import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-toolbar-email',
  templateUrl: './toolbar-email.component.html',
  styleUrls: ['./toolbar-email.component.scss']
})
export class ToolbarEmailComponent implements OnInit {

  isOpen: boolean;

  constructor() { }

  ngOnInit() {
  }
}
